import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { LanguageConverter } from "../../enums/LanguageConverter";
import ConfigurationView from "./ConfigurationView";
import AdvancedTablePagination from "../../components/AdvancedTablePagination";
import { useBackend } from "../../context/BackendContext";

export default function MainConfigurations() {
  const { t, i18n } = useTranslation();
  const backend = useBackend();
  const [carBrands, setCarBrands] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [alarmModels, setAlarmModels] = useState([]);
  const [searchAlarmInput, setSearchAlarmInput] = useState(-1);
  const [searchCarBrandInput, setSearchCarBrandInput] = useState(-1);
  const [searchCarModelInput, setSearchCarModelInput] = useState(-1);
  const [configurationsData, setConfigurationsData] = useState(null);
  const [configurationsCount, setConfigurationsCount] = useState(0);
  const [hoverOnConfigId, setHoverOnConfigId] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    backend.bckCarsListBrands(true, true, false, null).then((json) => {
      setCarBrands(json.content);
    });
    backend.bckDevicesList("", false, true).then((json) => {
      setAlarmModels(json.content);
    });
  }, []);

  const onSelectCarBrand = (e) => {
    setSearchCarBrandInput(e.target.value);
    setSearchCarModelInput("");
    setCarModels([]);
    if (e.target.value !== -1) {
      backend
        .bckCarsListModels(e.target.value, true, true, null)
        .then((json) => {
          setCarModels(json.content);
        });
    }
  };

  const onSelectCarModel = (e) => {
    setSearchCarModelInput(e.target.value);
  };

  const onSelectAlarm = (e) => {
    setSearchAlarmInput(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    var newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPageNumber(0);
  };

  useEffect(() => {
    setConfigurationsData(null);
    let effCarBrand = searchCarBrandInput == -1 ? "" : searchCarBrandInput;
    let effCarModel = searchCarModelInput == -1 ? "" : searchCarModelInput;
    let effAlarm = searchAlarmInput == -1 ? "" : searchAlarmInput;
    backend
      .bckConfigurationsList(
        effCarBrand,
        effCarModel,
        effAlarm,
        LanguageConverter[i18n.language],
        "",
        LanguageConverter[i18n.language],
        true,
        pageNumber,
        pageSize
      )
      .then((json) => {
        setConfigurationsCount(json.content.count);
        setConfigurationsData(json.content.configurations);
      });
  }, [
    searchAlarmInput,
    searchCarBrandInput,
    searchCarModelInput,
    i18n.language,
    pageNumber,
    pageSize,
  ]);

  useEffect(() => {
    setPageNumber(0);
  }, [
    searchAlarmInput,
    searchCarBrandInput,
    searchCarModelInput,
    i18n.language,
  ]);

  return (
    <div
      className={styles.container}
      style={{ paddingTop: "16px", minHeight: "95vh" }}
    >
      <div style={{ width: "100%", maxWidth: "1100px" }}>
        <div style={{ margin: "16px" }}>
          <h2>{t("MAIN_CONFIGURATION_HEADER")}</h2>
        </div>
        <Paper
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding: "16px",
          }}
        >
          <FormControl style={{ flexBasis: "30%", marginRight: "16px" }}>
            <InputLabel>{t("Alarm")}</InputLabel>
            <Select
              value={searchAlarmInput}
              onChange={onSelectAlarm}
              label={t("Alarm")}
              disabled={!alarmModels || alarmModels.length === 0}
            >
              <MenuItem key={-1} value={-1}>
                <em>{t("Any (fem)")}</em>
              </MenuItem>
              {alarmModels == null
                ? ""
                : alarmModels.map((a) => (
                    <MenuItem key={a.id} value={a.id}>
                      {a.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <FormControl style={{ flexBasis: "30%", marginRight: "16px" }}>
            <InputLabel>{t("Car brand")}</InputLabel>
            <Select
              value={searchCarBrandInput}
              onChange={onSelectCarBrand}
              label={t("Car brand")}
              disabled={!carBrands || carBrands.length === 0}
            >
              <MenuItem key={-1} value={-1}>
                <em>{t("Any (mas)")}</em>
              </MenuItem>
              {carBrands == null
                ? ""
                : carBrands.map((cb) => (
                    <MenuItem key={cb.id} value={cb.id}>
                      {cb.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <FormControl style={{ flexBasis: "30%", marginRight: "16px" }}>
            <InputLabel>{t("Car model")}</InputLabel>
            <Select
              value={searchCarModelInput}
              onChange={onSelectCarModel}
              label={t("Car model")}
              disabled={!searchCarBrandInput || carModels.length === 0}
            >
              <MenuItem key={-1} value={-1}>
                <em>{t("Any (fem)")}</em>
              </MenuItem>
              {carModels.map((cm) => (
                <MenuItem key={cm.id} value={cm.id}>
                  {cm.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>
        {configurationsData ? (
          configurationsData.length > 0 ? (
            <AdvancedTablePagination
              rowsPerPageOptions={[5, 10, 20]}
              totalRows={configurationsCount}
              pageSize={pageSize}
              pageNumber={pageNumber}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {configurationsData.map((c, i) => (
                <div id={c.id}>
                  {c.id === hoverOnConfigId ? (
                    <Paper
                      elevation={c.id === hoverOnConfigId ? 24 : 0}
                      style={{ padding: "16px" }}
                      onMouseLeave={() => setHoverOnConfigId(0)}
                    >
                      <ConfigurationView data={c} key={c.id} />
                    </Paper>
                  ) : (
                    <div
                      style={{ padding: "16px" }}
                      onMouseLeave={() => setHoverOnConfigId(0)}
                      onMouseEnter={() => setHoverOnConfigId(c.id)}
                    >
                      <ConfigurationView data={c} key={c.id} />
                    </div>
                  )}

                  {i + 1 < configurationsData.length ? <Divider /> : <></>}
                </div>
              ))}
            </AdvancedTablePagination>
          ) : (
            <>
              <h3>{t("Elements not found")}</h3>
            </>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
