import React, { useState, useEffect, useRef, useCallback } from "react";

import { Grid, Typography, Button, Paper } from "@mui/material";

import { GenerateInteger } from "../helpers/RandomGenerator";

import { useTranslation } from "react-i18next";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import CarEquipmentsTable from "../components/Tables/CarEquipmentsTable/CarEquipmentsTable";
import { LanguageConverter } from "../enums/LanguageConverter";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";

export default function CarEquipments({ initialModelId }) {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [error, setError] = useState("");
  const [tableData, setTableData] = useState([]);
  const [searchParameters, setSearchParameters] = useState({
    modelId: null,
    canCode: null,
  });
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const refreshTable = useCallback(
    (searchParameters) => {
      if (!searchParameters.modelId && !searchParameters.canCode) return;
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckCarsListEquipments(
          searchParameters.modelId,
          searchParameters.canCode,
          LanguageConverter[i18n.language],
          null
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }
        });
    },
    [t, i18n.language, backend]
  );

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    let params = queryString.parse(location.search);
    let newSearchParameters = { ...searchParameters };
    if (initialModelId) {
      newSearchParameters.modelId = initialModelId;
    } else if (params.modelId) {
      newSearchParameters.modelId = params.modelId;
    } else if (params.canCode) {
      let decCanCode = parseInt(params.canCode, 16);
      newSearchParameters.canCode = decCanCode;
    } else {
      setError("Model ID or CAN code chould be set");
    }
    setSearchParameters(newSearchParameters);
    refreshTable(newSearchParameters);
  }, [location]);

  const handleCreateEquipmentOpen = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (title) => {
        ++updatesLockCounter.current;

        let id = GenerateInteger();
        lastRequestId.current = id;
        backend
          .bckCarsCreateOrUpdateEquipment(
            null,
            searchParameters.modelId,
            title,
            null,
            false,
            null,
            null,
            false,
            null,
            null,
            null,
            null,
            null,
            LanguageConverter[i18n.language],
            null
          )
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              let equipmentId = json.content;
              let newElement = {
                id: equipmentId,
                name: title,
              };
              setTableData((prev) => {
                let newArr = [newElement, ...prev];
                return newArr;
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      initialValue: "",
      textLabel: t("Title"),
      title: t("Add equipment"),
    });
  };

  const handleRemoveEquipment = (equipment) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove equipment?", { title: equipment.name }),
      handleConfirm: () => {
        ++updatesLockCounter.current;
        backend.bckCarsRemoveEquipment(equipment.id).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
            setTableData((prev) => {
              let foundIndex = prev.findIndex((x) => x.id === equipment.id);
              if (foundIndex !== -1) {
                prev.splice(foundIndex, 1);
              }
              return [...prev];
            });
          } else if (json.code === 3) {
            setError(t(json.message));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const handleCanCodeClick = (equipment) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (canCode) => {
        ++updatesLockCounter.current;

        let id = GenerateInteger();
        lastRequestId.current = id;
        backend
          .bckCarsCreateOrUpdateEquipment(
            equipment.id,
            searchParameters.modelId,
            equipment.title,
            canCode,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            LanguageConverter[i18n.language],
            null
          )
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              let equipmentId = json.content;
              setTableData((prev) => {
                var foundIndex = prev.findIndex((x) => x.id === equipmentId);
                if (foundIndex !== -1) {
                  prev[foundIndex].canCode = canCode;
                }
                return [...prev];
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      initialValue: equipment.canCode,
      textLabel: "CAN",
      title: "CAN",
      onChange: (e) => {
        let filteredValue = e.target.value.replace(/[^0-9]/g, "");
        return filteredValue.substr(0, 4);
      },
    });
  };

  const handleNameClick = (equipment) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        ++updatesLockCounter.current;

        let id = GenerateInteger();
        lastRequestId.current = id;
        backend
          .bckCarsCreateOrUpdateEquipment(
            equipment.id,
            searchParameters.modelId,
            name,
            equipment.canCode,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            LanguageConverter[i18n.language],
            null
          )
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              let equipmentId = json.content;
              setTableData((prev) => {
                var foundIndex = prev.findIndex((x) => x.id === equipmentId);
                if (foundIndex !== -1) {
                  prev[foundIndex].name = name;
                }
                return [...prev];
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      initialValue: equipment.name,
      textLabel: t("Title"),
      title: t("Title"),
    });
  };

  const handleShowEquipmentFull = (equipment) => {
    navigate(`/app/carEquipmentFull?id=${equipment.id}`);
  };

  return (
    <>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <Typography variant="h6" color="textSecondary">
            {t("Equipments table")}
          </Typography>
          <Typography color="secondary">{error}</Typography>
          {searchParameters.modelId ? (
            <Grid item>
              <Button
                onClick={handleCreateEquipmentOpen}
                variant="contained"
                color="primary"
              >
                {t("Add equipment")}
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          <CarEquipmentsTable
            data={tableData}
            handleRemoveEquipment={handleRemoveEquipment}
            handleShowEquipmentFull={handleShowEquipmentFull}
            handleCanCodeClick={handleCanCodeClick}
            handleNameClick={handleNameClick}
          />
        </Grid>
      </Paper>
    </>
  );
}
