import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Paper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

// components
import MountsTable from "../components/Tables/FastMountsTable";
import MountQuestionsDialog from "../components/Dialogs/MountQuestionsDialog";
import { GenerateInteger } from "../helpers/RandomGenerator";
import { useInterval } from "../useInterval";

import { LanguageConverter } from "../enums/LanguageConverter";

import { generateUserInfoString } from "../helpers/UserInfoStringGenerator";
import { useTranslation } from "react-i18next";

import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import AdvancedTablePagination from "../components/AdvancedTablePagination";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoadBool,
  settingRepositoryLoadInt,
  settingRepositorySave,
} from "../settingRepository";

export default function FastMounts({
  noFiltersMode,
  customHeight,
  customSearchDeviceId,
}) {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [error, setError] = useState("");
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [carBrands, setCarBrands] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [alarmModels, setAlarmModels] = useState([]);
  const [searchStatusInput, setSearchStatusInput] = useState(-1);
  const [searchStatus, setSearchStatus] = useState("");
  const [searchUserInput, setSearchUserInput] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [searchCityInput, setSearchCityInput] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchCarBrandInput, setSearchCarBrandInput] = useState(-1);
  const [searchCarBrand, setSearchCarBrand] = useState("");
  const [searchCarModelInput, setSearchCarModelInput] = useState(-1);
  const [searchCarModel, setSearchCarModel] = useState("");
  const [searchAlarmModelInput, setSearchAlarmModelInput] = useState(-1);
  const [searchAlarmModel, setSearchAlarmModel] = useState("");
  const [searchLanguageInput, setSearchLanguageInput] = useState(-1);
  const [searchLanguage, setSearchLanguage] = useState("");
  const [searchDeviceQueryInput, setSearchDeviceQueryInput] = useState("");
  const [searchDeviceQuery, setSearchDeviceQuery] = useState("");
  const [searchDeviceId, setSearchDeviceId] = useState("");
  const [searchDeviceIdInput, setSearchDeviceIdInput] = useState("");
  const [totalRows, setTotalRows] = useState(100);
  const [questions, setQuestions] = useState([]);
  const [isMountQuestionsOpened, setIsMountQuestionsOpened] = useState(false);
  const [currentMountAnswers, setCurrentMountAnswers] = useState([]);
  const [currentMountId, setCurrentMountId] = useState(0);
  const [currentMountQuestions, setCurrentMountQuestions] = useState([]);
  const [autoUpdateChecked, setAutoUpdateChecked] = useState(true);
  const latestFirmwares = useRef({});
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const mountStatuses = [
    { key: 1, value: () => t("Mount in progress") },
    { key: 2, value: () => t("Mount finished") },
    { key: 3, value: () => t("Mount interrupted") },
  ];

  const handleUsergroupClick = (mount) => {
    backend.bckFastMountsGetUsergroupOwners(mount.id).then((json) => {
      if (json.code === 0) {
        dialogDispatch({
          type: DialogActionTypes.UsergroupListDialogOpen,
          list: json.content,
        });
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const loadLatestFirmwareVersion = useCallback(
    (name) => {
      backend.bckDevicesLatestFirmwareVersion(name).then((json) => {
        if ("content" in json) {
          if (json.content === null) json.content = "";
          latestFirmwares.current[name] = json.content;
          setTableData((tableData) => {
            const newTable = [...tableData];
            newTable.forEach((r) => {
              if (r.alarmName === name) r.latestFirmware = json.content;
            });
            return newTable;
          });
        }
      });
    },
    [backend]
  );

  const loadQuestions = useCallback(
    (backend) => {
      backend
        .bckQuestionsListQuestions(LanguageConverter[i18n.language])
        .then((json) => {
          setQuestions(json.content);
        });
    },
    [i18n.language]
  );

  const refreshTable = useCallback(
    (
      searchStatus,
      searchUser,
      searchDevice,
      searchCarBrand,
      searchCarModel,
      searchAlarmModel,
      searchCity,
      searchDeviceId,
      searchLanguage,
      pageNumber,
      pageSize,
      isInitializing
    ) => {
      const getLatestFirmwareVersion = (version, alarmName) => {
        if (alarmName in latestFirmwares.current) {
          var latest = latestFirmwares.current[alarmName];
          if (version === latest) return version;
          return latest;
        } else {
          latestFirmwares.current[alarmName] = t("Loading");
          loadLatestFirmwareVersion(alarmName);
          return t("Loading");
        }
      };

      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckFastMountsList(
          searchStatus,
          searchUser,
          searchDevice,
          searchCarBrand,
          searchCarModel,
          searchAlarmModel,
          searchLanguage,
          searchCity,
          customSearchDeviceId ? customSearchDeviceId : searchDeviceId,
          LanguageConverter[i18n.language],
          pageNumber + 1,
          pageSize
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              json.content.rows.forEach((r) => {
                r.latestFirmware = getLatestFirmwareVersion(
                  r.version,
                  r.alarmName
                );
              });
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.rows;
                } else {
                  return prev;
                }
              });
              setTotalRows((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.total;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }

          if (isInitializing) {
            setIsInitializing(false);
            setIsLoading(false);
          }
        });
    },
    [loadLatestFirmwareVersion, t, i18n.language, customSearchDeviceId, backend]
  );

  const loadCarBrands = (backend) => {
    backend.bckCarsListBrands(true, true, false, null).then((json) => {
      setCarBrands(json.content);
    });
  };

  const loadAlarms = (backend) => {
    backend.bckDevicesList("", true, false).then((json) => {
      setAlarmModels(json.content);
    });
  };

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useInterval(() => {
    if (updatesLockCounter.current === 0 && autoUpdateChecked) {
      refreshTable(
        searchStatus,
        searchUser,
        searchDeviceQuery,
        searchCarBrand,
        searchCarModel,
        searchAlarmModel,
        searchCity,
        searchDeviceId,
        searchLanguage,
        pageNumber,
        pageSize,
        isInitializing
      );
    }
  }, 2000);

  useEffect(() => {
    if (!isInitializing) {
      loadQuestions(backend);
      refreshTable(
        searchStatus,
        searchUser,
        searchDeviceQuery,
        searchCarBrand,
        searchCarModel,
        searchAlarmModel,
        searchCity,
        searchDeviceId,
        searchLanguage,
        pageNumber,
        pageSize,
        isInitializing
      );
    }
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    if (isInitializing) {
      setAutoUpdateChecked(
        settingRepositoryLoadBool(SettingName.AUTO_UPDATE_MOUNTS, true)
      );

      let pageSize = settingRepositoryLoadInt(SettingName.MOUNTS_PAGE_SIZE, 10);
      setPageSize(pageSize);

      resetSearchParams();
      loadCarBrands(backend);
      loadAlarms(backend);
      loadQuestions(backend);

      var params = queryString.parse(location.search);
      if (params.login) {
        setSearchUserInput(params.login);
        setSearchUser(params.login);
        refreshTable(
          "",
          params.login,
          "",
          "",
          "",
          "",
          "",
          "",
          null,
          0,
          pageSize,
          true
        );
      } else {
        refreshTable("", "", "", "", "", "", "", "", null, 0, pageSize, true);
      }
    }
  }, [isInitializing, location]);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    refreshTable(
      searchStatus,
      searchUser,
      searchDeviceQuery,
      searchCarBrand,
      searchCarModel,
      searchAlarmModel,
      searchCity,
      searchDeviceId,
      searchLanguage,
      newPage,
      pageSize,
      isInitializing
    );
  };

  const handleChangeRowsPerPage = (event) => {
    var newPageSize = parseInt(event.target.value, 10);
    settingRepositorySave(SettingName.MOUNTS_PAGE_SIZE, newPageSize);
    setPageSize(newPageSize);
    setPageNumber(0);
    refreshTable(
      searchStatus,
      searchUser,
      searchDeviceQuery,
      searchCarBrand,
      searchCarModel,
      searchAlarmModel,
      searchCity,
      searchDeviceId,
      searchLanguage,
      pageNumber,
      newPageSize,
      isInitializing
    );
  };

  const filterQuestions = (questions, mountQuestions) => {
    if (questions === null || questions === undefined) return [];
    if (mountQuestions === null || mountQuestions === undefined)
      return questions;
    return questions.filter((q) => mountQuestions.some((mq) => mq === q.id));
  };

  const handleMountQuestionsOpen = (mount) => {
    setCurrentMountId(mount.id);
    setCurrentMountAnswers(mount.answers);
    setCurrentMountQuestions(filterQuestions(questions, mount.questions));
    setIsMountQuestionsOpened(true);
  };

  const handleMountQuestionsClose = () => {
    setIsMountQuestionsOpened(false);
  };

  const handleCommentOnMount = (mount) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (value) => {
        tableData.find((m) => m.id === mount.id).comment = value;
        backend.bckFastMountsSetComment(mount.id, value);
      },
      initialValue: mount.comment,
      textLabel: t("Comment"),
      title: t("Comment on mount"),
    });
  };

  const resetSearchParams = () => {
    setSearchCarBrand("");
    setSearchCarBrandInput(-1);
    setSearchCarModel("");
    setSearchCarModelInput(-1);
    setSearchAlarmModel("");
    setSearchAlarmModelInput(-1);
    setSearchUserInput("");
    setSearchUser("");
    setSearchCityInput("");
    setSearchCity("");
    setSearchStatusInput(-1);
    setSearchStatus("");
    setSearchLanguageInput(-1);
    setSearchLanguage("");
    setCarModels([]);
    setSearchDeviceQuery("");
    setSearchDeviceQueryInput("");
    setSearchDeviceId("");
    setSearchDeviceIdInput("");
    setPageNumber(0);
  };

  const updateFilters = () => {
    setSearchCarBrandInput(searchCarBrand === "" ? -1 : searchCarBrand);
    setSearchCarModelInput(searchCarModel === "" ? -1 : searchCarModel);
    setSearchAlarmModelInput(searchAlarmModel === "" ? -1 : searchAlarmModel);
    setSearchStatusInput(searchStatus === "" ? -1 : searchStatus);
    setSearchLanguageInput(searchLanguage === "" ? -1 : searchLanguage);
    setSearchUserInput(searchUser);
    setSearchCityInput(searchCity);
    setSearchDeviceQueryInput(searchDeviceQuery);
    setSearchDeviceIdInput(searchDeviceId);
  };

  const handleSearchPress = () => {
    var newSearchCarBrand = "";
    if (searchCarBrandInput === -1) {
      setSearchCarBrand("");
    } else {
      newSearchCarBrand = searchCarBrandInput;
      setSearchCarBrand(searchCarBrandInput);
    }

    var newSearchCarModel = "";
    if (searchCarModelInput === -1) {
      setSearchCarModel("");
    } else {
      newSearchCarModel = searchCarModelInput;
      setSearchCarModel(searchCarModelInput);
    }

    var newSearchAlarmModel = "";
    if (searchAlarmModelInput === -1) {
      setSearchAlarmModel("");
    } else {
      newSearchAlarmModel = searchAlarmModelInput;
      setSearchAlarmModel(searchAlarmModelInput);
    }

    var newSearchUser = "";
    if (searchUserInput.trim() === "") {
      setSearchUser("");
    } else {
      newSearchUser = searchUserInput;
      setSearchUser(searchUserInput);
    }

    var newSearchStatus = "";
    if (searchStatusInput === -1) {
      setSearchStatus("");
    } else {
      newSearchStatus = searchStatusInput;
      setSearchStatus(searchStatusInput);
    }

    var newSearchLanguage = "";
    if (searchLanguageInput === -1) {
      setSearchLanguage("");
    } else {
      newSearchLanguage = searchLanguageInput;
      setSearchLanguage(searchLanguageInput);
    }

    var newSearchCity = "";
    if (searchCityInput.trim() === "") {
      setSearchCity("");
    } else {
      newSearchCity = searchCityInput;
      setSearchCity(searchCityInput);
    }

    var newSearchDevice = "";
    if (searchDeviceQueryInput.trim() === "") {
      setSearchDeviceQuery("");
    } else {
      newSearchDevice = searchDeviceQueryInput;
      setSearchDeviceQuery(searchDeviceQueryInput);
    }

    setSearchDeviceId(searchDeviceIdInput);
    setPageNumber(0);
    refreshTable(
      newSearchStatus,
      newSearchUser,
      newSearchDevice,
      newSearchCarBrand,
      newSearchCarModel,
      newSearchAlarmModel,
      newSearchCity,
      searchDeviceIdInput,
      newSearchLanguage,
      0,
      pageSize,
      isInitializing
    );
  };

  const handleSearchResetPress = () => {
    resetSearchParams();
    refreshTable(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      null,
      0,
      pageSize,
      isInitializing
    );
  };

  const setMountState = (mountId, state) => {
    ++updatesLockCounter.current;
    tableData.find((m) => m.id === mountId).state = state;
    backend.bckFastMountsSetState(mountId, state).then((json) => {
      --updatesLockCounter.current;
      refreshTable(
        searchStatus,
        searchUser,
        searchDeviceQuery,
        searchCarBrand,
        searchCarModel,
        searchAlarmModel,
        searchCity,
        searchDeviceId,
        searchLanguage,
        pageNumber,
        pageSize,
        isInitializing
      );
    });
  };

  const handleContinueMount = (mountId) => {
    setMountState(mountId, 1);
  };

  const handlePauseMount = (mountId) => {
    setMountState(mountId, 3);
  };

  const onSelectCarBrand = (e) => {
    setSearchCarBrandInput(e.target.value);
    setSearchCarModelInput(-1);
    if (e.target.value !== -1) {
      backend
        .bckCarsListModels(e.target.value, true, true, null)
        .then((json) => {
          setCarModels(json.content);
        });
    }
  };

  const handleExpanded = () => {
    ++updatesLockCounter.current;
  };

  const handleCollapsed = () => {
    --updatesLockCounter.current;
  };

  const handleShowMountAddress = (mount) => {
    let action = {
      type: DialogActionTypes.AddressDialogOpen,
      mapDisabled: false,
      handleOpenMap: () =>
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${mount.coordinates.replace(
            ";",
            ","
          )}`
        ),
      text: t("Loading"),
    };
    if (mount.addressId === null || mount.addressId === undefined) {
      action.text = t("Address not detected");
      dialogDispatch(action);
    } else {
      backend.bckAddressGetLocationString(mount.addressId).then((json) => {
        if (json.content !== null) {
          action.text = json.content;
        } else {
          action.text = t("Address not detected");
        }
        dialogDispatch(action);
      });
    }
  };

  const handleShowUserInfo = (mount) => {
    backend.bckUsersGetInfo(mount.userId).then((json) => {
      if (json.code === 0) {
        let info = json.content;
        dialogDispatch({
          type: DialogActionTypes.SimpleOutputDialogOpen,
          title: "",
          textLabel: "",
          text: generateUserInfoString(t, info),
          noTextField: true,
        });
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleUserEmailClick = (mount) => {
    updateFilters();
    setSearchUserInput(mount.userMail);
    setSearchUser(mount.userMail);
    setPageNumber(0);
    refreshTable(
      searchStatus,
      mount.userMail,
      searchDeviceQuery,
      searchCarBrand,
      searchCarModel,
      searchAlarmModel,
      searchCity,
      searchDeviceId,
      searchLanguage,
      0,
      pageSize,
      isInitializing
    );
  };

  const handleShowDevicePackage = (deviceId) => {
    if (deviceId) {
      dialogDispatch({
        type: DialogActionTypes.FactoryPackageDialogOpen,
        deviceId: deviceId,
      });
    }
  };

  const handleDeviceIdClick = (mount) => {
    updateFilters();
    console.log(mount);
    setSearchDeviceIdInput(mount.deviceId.toString());
    setSearchDeviceId(mount.deviceId);
    setPageNumber(0);
    refreshTable(
      searchStatus,
      searchUser,
      searchDeviceQuery,
      searchCarBrand,
      searchCarModel,
      searchAlarmModel,
      searchCity,
      mount.deviceId,
      searchLanguage,
      0,
      pageSize,
      isInitializing
    );
  };

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: customHeight ? customHeight : "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Typography color="secondary">{error}</Typography>
      <Paper
        style={{
          overflow: "auto",
          padding: "24px",
          maxHeight: customHeight ? customHeight : "85vh",
        }}
      >
        {noFiltersMode ? (
          <></>
        ) : (
          <div>
            <Typography variant="h5" color="textSecondary">
              {t("Mounts table")}
            </Typography>
            <Grid container direction="column" style={{ paddingTop: "12px" }}>
              <Grid item>
                <TextField
                  label={t("User")}
                  value={searchUserInput}
                  onChange={(e) => setSearchUserInput(e.target.value)}
                  style={{ marginRight: "12px", marginBottom: "12px" }}
                />
                <TextField
                  label={t("Device ID")}
                  value={searchDeviceIdInput}
                  onChange={(e) => {
                    let filteredValue = e.target.value.replace(/[^0-9]/g, "");
                    setSearchDeviceIdInput(filteredValue.substr(0, 12));
                  }}
                  style={{ marginRight: "12px", marginBottom: "12px" }}
                />
                <TextField
                  label={t("Address")}
                  value={searchCityInput}
                  onChange={(e) => setSearchCityInput(e.target.value)}
                  style={{ marginRight: "12px", marginBottom: "12px" }}
                />
                <TextField
                  label={t("Device")}
                  value={searchDeviceQueryInput}
                  onChange={(e) => setSearchDeviceQueryInput(e.target.value)}
                  style={{ marginRight: "12px", marginBottom: "12px" }}
                />
                <FormControl
                  style={{
                    marginRight: "12px",
                    minWidth: "25ch",
                    marginBottom: "12px",
                  }}
                >
                  <InputLabel>{t("Status")}</InputLabel>
                  <Select
                    label={t("Status")}
                    value={searchStatusInput}
                    onChange={(e) => setSearchStatusInput(e.target.value)}
                  >
                    <MenuItem key={-1} value={-1}>
                      <em>{t("Any (mas)")}</em>
                    </MenuItem>
                    {mountStatuses.map((ms) => (
                      <MenuItem key={ms.key} value={ms.key}>
                        {ms.value()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    marginRight: "12px",
                    minWidth: "25ch",
                    marginBottom: "12px",
                  }}
                >
                  <InputLabel>{t("Car brand")}</InputLabel>
                  <Select
                    label={t("Car brand")}
                    value={searchCarBrandInput}
                    onChange={onSelectCarBrand}
                  >
                    <MenuItem key={-1} value={-1}>
                      <em>{t("Any (mas)")}</em>
                    </MenuItem>
                    {carBrands.map((cb) => (
                      <MenuItem key={cb.id} value={cb.id}>
                        {cb.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    marginRight: "12px",
                    minWidth: "25ch",
                    marginBottom: "12px",
                  }}
                >
                  <InputLabel>{t("Car model")}</InputLabel>
                  <Select
                    label={t("Car model")}
                    value={searchCarModelInput}
                    onChange={(e) => setSearchCarModelInput(e.target.value)}
                  >
                    <MenuItem key={-1} value={-1}>
                      <em>{t("Any (fem)")}</em>
                    </MenuItem>
                    {carModels.map((cm) => (
                      <MenuItem key={cm.id} value={cm.id}>
                        {cm.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    marginRight: "12px",
                    minWidth: "25ch",
                    marginBottom: "12px",
                  }}
                >
                  <InputLabel>{t("Alarm")}</InputLabel>
                  <Select
                    label={t("Alarm")}
                    value={searchAlarmModelInput}
                    onChange={(e) => setSearchAlarmModelInput(e.target.value)}
                  >
                    <MenuItem key={-1} value={-1}>
                      <em>{t("Any (fem)")}</em>
                    </MenuItem>
                    {alarmModels.map((a) => (
                      <MenuItem key={a.id} value={a.id}>
                        {a.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    marginRight: "12px",
                    minWidth: "12ch",
                    marginBottom: "12px",
                  }}
                >
                  <InputLabel>{t("Language")}</InputLabel>
                  <Select
                    label={t("Language")}
                    value={searchLanguageInput}
                    onChange={(e) => setSearchLanguageInput(e.target.value)}
                  >
                    <MenuItem key={-1} value={-1}>
                      <em>{t("Any (mas)")}</em>
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      RU
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      EN
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item>
                  <Button
                    onClick={handleSearchPress}
                    variant="contained"
                    color="primary"
                  >
                    {t("Search")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleSearchResetPress}
                    disabled={
                      searchCarBrandInput === -1 &&
                      searchAlarmModelInput === -1 &&
                      searchUserInput.trim() === "" &&
                      searchCityInput.trim() === "" &&
                      searchStatusInput === -1 &&
                      searchLanguageInput === -1 &&
                      searchDeviceQueryInput.trim() === "" &&
                      searchDeviceIdInput.trim() === ""
                    }
                    variant="contained"
                    color="primary"
                  >
                    {t("Reset parameters")}
                  </Button>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={autoUpdateChecked}
                        onChange={() => {
                          setAutoUpdateChecked(!autoUpdateChecked);
                          settingRepositorySave(
                            SettingName.AUTO_UPDATE_MOUNTS,
                            !autoUpdateChecked
                          );
                        }}
                        color="primary"
                      />
                    }
                    label={t("Auto update")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        <AdvancedTablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          totalRows={totalRows}
          pageSize={pageSize}
          pageNumber={pageNumber}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          <MountsTable
            data={tableData}
            handleDeviceIdClick={handleDeviceIdClick}
            handleMountQuestionsOpen={handleMountQuestionsOpen}
            handleCommentOnMount={handleCommentOnMount}
            handleContinueMount={handleContinueMount}
            handlePauseMount={handlePauseMount}
            handleExpanded={handleExpanded}
            handleCollapsed={handleCollapsed}
            handleShowMountAddress={handleShowMountAddress}
            handleShowUserInfo={handleShowUserInfo}
            handleUserEmailClick={handleUserEmailClick}
            handleShowDevicePackage={handleShowDevicePackage}
            handleUsergroupClick={handleUsergroupClick}
          />
        </AdvancedTablePagination>
      </Paper>

      <MountQuestionsDialog
        answers={currentMountAnswers}
        questions={currentMountQuestions}
        mountId={currentMountId}
        isOpened={isMountQuestionsOpened}
        handleClose={handleMountQuestionsClose}
      />
    </>
  );
}
