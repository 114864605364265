import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { Autocomplete } from "@mui/material";
import { LanguageConverter } from "../../enums/LanguageConverter";
import { useBackend } from "../../context/BackendContext";

export default function SelectEquipmentDialog({
  isOpened,
  brandsList,
  handleConfirm,
  handleClose,
}) {
  const { t, i18n } = useTranslation();
  const backend = useBackend();
  const [modelsList, setModelsList] = useState([]);
  const [equipmentsList, setEquipmentsList] = useState([]);
  const [brand, setBrand] = useState(null);
  const [model, setModel] = useState(null);
  const [equipment, setEquipment] = useState(null);

  useEffect(() => {
    if (isOpened) {
      setBrand(null);
      setModel(null);
      setEquipment(null);
    }
  }, [isOpened]);

  const handleConfirmInner = (e) => {
    handleClose();
    handleConfirm(brand, model, equipment);
  };

  const onBrandChanged = (event, value) => {
    setBrand(value);
    backend.bckCarsListModels(value.id, false, true, null).then((json) => {
      if (json.code === 0) {
        setModelsList(json.content);
      }
    });
  };

  const onModelChanged = (event, value) => {
    setModel(value);
    backend
      .bckCarsListEquipments(
        value.id,
        null,
        LanguageConverter[i18n.language],
        null
      )
      .then((json) => {
        if (json.code === 0) {
          setEquipmentsList(json.content);
        }
      });
  };

  const onEquipmentChanged = (event, value) => {
    setEquipment(value);
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{t("Equipment")}</DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: "8px" }}>
          <Autocomplete
            options={brandsList}
            onChange={onBrandChanged}
            getOptionLabel={(o) => o.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Car brand")}
                placeholder={t("Car brand")}
              />
            )}
            noOptionsText={t("No options")}
          />
        </div>
        <div style={{ marginBottom: "8px" }}>
          <Autocomplete
            disabled={!brand}
            options={modelsList}
            onChange={onModelChanged}
            getOptionLabel={(o) => o.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Car model")}
                placeholder={t("Car model")}
              />
            )}
            noOptionsText={t("No options")}
          />
        </div>
        <div>
          <Autocomplete
            disabled={!model}
            options={equipmentsList}
            onChange={onEquipmentChanged}
            getOptionLabel={(o) => o.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Equipment")}
                placeholder={t("Equipment")}
              />
            )}
            noOptionsText={t("No options")}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Cancel")}
        </Button>
        <Button
          disabled={!(brand || model || equipment)}
          onClick={handleConfirmInner}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
