import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Paper,
  Autocomplete,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { LanguageConverter } from "../../enums/LanguageConverter";
import EquipmentView from "./EquipmentView";
import { yearsStringToSpanString } from "../../helpers/StringUtils";
import { useBackend } from "../../context/BackendContext";
import {
  CarStatusGroups,
  getCarStatusGroupString,
} from "../../enums/CarStatusGroups";
import { useLocation, useSearchParams } from "react-router-dom";
import queryString from "query-string";

export default function MainAutomobiles() {
  const { t, i18n } = useTranslation();
  const backend = useBackend();
  const [carBrands, setCarBrands] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [carEquipments, setCarEquipments] = useState([]);
  const [currentParams, setCurrentParams] = useState({
    brand: null,
    model: null,
    equipment: null,
    statusGroupIds: [],
  });
  const [modelData, setModelData] = useState(null);
  const [equipmentData, setEquipmentData] = useState(null);
  const [, setSearchParams] = useSearchParams();

  const location = useLocation();
  useEffect(() => {
    async function load() {
      let params = queryString.parse(location.search);

      let newCurrentParams = { ...currentParams };
      if (params.brand) {
        let newValue = parseInt(params.brand);
        newCurrentParams = { ...newCurrentParams, brand: newValue };
      } else {
        newCurrentParams = { ...newCurrentParams, brand: null };
      }
      if (params.model) {
        let newValue = parseInt(params.model);
        newCurrentParams = { ...newCurrentParams, model: newValue };
      } else {
        newCurrentParams = { ...newCurrentParams, model: null };
      }
      if (params.equipment) {
        let newValue = parseInt(params.equipment);
        newCurrentParams = { ...newCurrentParams, equipment: newValue };
      } else {
        newCurrentParams = { ...newCurrentParams, equipment: null };
      }

      if (carBrands.length === 0) {
        await backend
          .bckCarsListBrands(false, true, false, currentParams.statusGroupIds)
          .then((json) => {
            setCarBrands(json.content);
          });
      }
      if (
        currentParams.brand !== newCurrentParams.brand &&
        newCurrentParams.brand
      ) {
        await backend
          .bckCarsListModels(
            newCurrentParams.brand,
            false,
            true,
            newCurrentParams.statusGroupIds
          )
          .then((json) => {
            setCarModels(json.content);
          });
      }
      if (
        currentParams.model !== newCurrentParams.model &&
        newCurrentParams.model
      ) {
        await backend
          .bckCarsListEquipments(
            newCurrentParams.model,
            null,
            LanguageConverter[i18n.language],
            newCurrentParams.statusGroupIds
          )
          .then((json) => {
            setCarEquipments(json.content);
          });
      }
      if (
        currentParams.equipment !== newCurrentParams.equipment &&
        newCurrentParams.equipment
      ) {
        setEquipmentData(null);
        setModelData(null);
        await backend
          .bckCarsGetEquipmentInfo(
            newCurrentParams.equipment,
            LanguageConverter[i18n.language],
            false
          )
          .then((json) => {
            setEquipmentData(json.content);
            setModelData(
              carModels.find((cm) => cm.id === newCurrentParams.model)
            );
          });
      } else if (!newCurrentParams.equipment) {
        setEquipmentData(null);
        setModelData(null);
      }

      if (
        currentParams.brand !== newCurrentParams.brand ||
        currentParams.model !== newCurrentParams.model ||
        currentParams.equipment !== newCurrentParams.equipment
      ) {
        setCurrentParams(newCurrentParams);
      }
    }

    load();
  }, [location, backend, i18n.language, currentParams]);

  const setHref = useCallback(
    (brand, model, equipment) => {
      setSearchParams((searchParams) => {
        if (brand) {
          searchParams.set("brand", brand);
        } else {
          searchParams.delete("brand");
        }
        if (model) {
          searchParams.set("model", model);
        } else {
          searchParams.delete("model");
        }
        if (equipment) {
          searchParams.set("equipment", equipment);
        } else {
          searchParams.delete("equipment");
        }
        return searchParams;
      });
    },
    [setSearchParams]
  );

  const onClickStatusGroupCheckbox = (groupId) => {
    setCarBrands([]);
    setCarModels([]);
    setCarEquipments([]);
    setHref(null, null, null);
    setCurrentParams((prev) => {
      let next = [...prev.statusGroupIds];
      let existingIndex = next.findIndex((id) => id === groupId);
      if (existingIndex !== -1) {
        next.splice(existingIndex, 1);
      } else {
        next.push(groupId);
      }
      return { ...prev, statusGroupIds: next };
    });
  };

  const onSelectCarBrand = (...params) => {
    let newValue = params[1];
    setCarModels([]);
    setCarEquipments([]);
    if (newValue) {
      setHref(newValue.id, null, null);
    }
  };

  const onSelectCarModel = (...params) => {
    let newValue = params[1];
    setCarEquipments([]);
    if (newValue) {
      setHref(currentParams.brand, newValue.id, null);
    }
  };

  const onSelectCarEquipment = (...params) => {
    let newValue = params[1];
    setHref(currentParams.brand, currentParams.model, newValue.id);
  };

  const selectedBrand = useMemo(() => {
    if (carBrands === null) return null;
    return carBrands.find((cb) => cb.id === currentParams.brand) ?? null;
  }, [carBrands, currentParams]);

  const selectedModel = useMemo(() => {
    if (carModels === null) return null;
    return carModels.find((cm) => cm.id === currentParams.model) ?? null;
  }, [carModels, currentParams]);

  const selectedEquipment = useMemo(() => {
    if (carEquipments === null) return null;
    return (
      carEquipments.find((ce) => ce.id === currentParams.equipment) ?? null
    );
  }, [carEquipments, currentParams]);

  return (
    <div
      className={styles.container}
      style={{ paddingTop: "16px", minHeight: "95vh" }}
    >
      <div style={{ width: "100%", maxWidth: "1100px" }}>
        <div style={{ margin: "16px" }}>
          <h2>{t("MAIN_AUTOMOBILES_HEADER")}</h2>
        </div>
        <Paper
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding: "16px",
          }}
        >
          <div
            style={{
              flexBasis: "32%",
              marginRight: "16px",
              marginBottom: "16px",
            }}
          >
            <Autocomplete
              value={selectedBrand}
              isOptionEqualToValue={(option, value) =>
                value ? option.id === value.id : false
              }
              getOptionLabel={(option) => option?.name ?? ""}
              options={
                carBrands == null
                  ? []
                  : carBrands.sort((a, b) => (a.name > b.name ? 1 : -1))
              }
              onChange={onSelectCarBrand}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Car brand")}
                  placeholder={t("Car brand")}
                />
              )}
              disabled={!carBrands || carBrands.length === 0}
              noOptionsText={t("No options")}
              disableClearable
            />
          </div>
          <div
            style={{
              flexBasis: "32%",
              marginRight: "16px",
              marginBottom: "16px",
            }}
          >
            <Autocomplete
              value={selectedModel}
              isOptionEqualToValue={(option, value) =>
                value ? option.id === value.id : false
              }
              getOptionLabel={(option) =>
                option?.name
                  ? `${option.name} (${yearsStringToSpanString(option.years)})`
                  : ""
              }
              options={
                carModels == null
                  ? []
                  : carModels.sort((a, b) => (a.name > b.name ? 1 : -1))
              }
              onChange={onSelectCarModel}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Car model")}
                  placeholder={t("Car model")}
                />
              )}
              disabled={
                !currentParams.brand || !carModels || carModels.length === 0
              }
              noOptionsText={t("No options")}
              disableClearable
            />
          </div>
          <div style={{ flexBasis: "32%", marginBottom: "16px" }}>
            <Autocomplete
              value={selectedEquipment}
              isOptionEqualToValue={(option, value) =>
                value ? option.id === value.id : false
              }
              getOptionLabel={(option) => option?.name ?? ""}
              options={
                carEquipments == null
                  ? []
                  : carEquipments.sort((a, b) => (a.name > b.name ? 1 : -1))
              }
              onChange={onSelectCarEquipment}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Equipment")}
                  placeholder={t("Equipment")}
                />
              )}
              disabled={
                !currentParams.model ||
                !carEquipments ||
                carEquipments.length === 0
              }
              noOptionsText={t("No options")}
              disableClearable
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography>
              <b>{t("Filter by parameters availability")}:</b>
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Object.keys(CarStatusGroups)
                .filter((id) => id !== "-1")
                .map((id) => (
                  <FormControlLabel
                    key={id}
                    control={
                      <Checkbox
                        color="primary"
                        defaultChecked={false}
                        onChange={(e) => onClickStatusGroupCheckbox(id)}
                      />
                    }
                    label={getCarStatusGroupString(t, parseInt(id))}
                  />
                ))}
            </div>
          </div>
        </Paper>
        {equipmentData ? (
          <EquipmentView data={equipmentData} modelData={modelData} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
