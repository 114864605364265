import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Grid,
  Typography,
  Button,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { GenerateInteger } from "../helpers/RandomGenerator";

import { useTranslation } from "react-i18next";
import CarBrandsTable from "../components/Tables/CarBrandsTable";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoadBool,
  settingRepositorySave,
} from "../settingRepository";
import { useNavigate } from "react-router";

export default function CarBrands() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [searchParameters, setSearchParameters] = useState({
    onlyWithModels: null,
  });
  const [tableData, setTableData] = useState([]);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const refreshTable = useCallback(
    (searchParameters) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckCarsListBrands(false, false, searchParameters.onlyWithModels, null)
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }
        });
    },
    [backend, t]
  );

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    let onlyWithModelsSetting = settingRepositoryLoadBool(
      SettingName.CAR_BRANDS_ONLY_WITH_MODELS,
      true
    );
    setSearchParameters({ onlyWithModels: onlyWithModelsSetting });
  }, []);

  useEffect(() => {
    if (searchParameters.onlyWithModels != null) {
      refreshTable(searchParameters);
    }
  }, [searchParameters]);

  const handleShowCarBrandImage = (brand) => {
    dialogDispatch({
      type: DialogActionTypes.ImageViewerOpen,
      image: {
        src: brand.imageUrl,
        alt: "",
      },
    });
  };

  const handleShowBrandModels = (brand) => {
    navigate(`/app/carModels?brandId=${brand.id}`);
  };

  const handleCreateBrandOpen = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (title) => {
        ++updatesLockCounter.current;

        let requestId = GenerateInteger();
        lastRequestId.current = requestId;
        backend.bckCarsCreateOrUpdateBrand(null, title).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
            let brandId = json.content;
            let newElement = {
              id: brandId,
              name: title,
              imageUrl: null,
            };
            setTableData((prev) => {
              let newArr = [newElement, ...prev];
              return newArr;
            });
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
      initialValue: "",
      textLabel: t("Title"),
      title: t("Add brand"),
    });
  };

  const handleSearchEquipmentsByCanOpen = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (canCode) => {
        navigate(`/app/carEquipments?canCode=${canCode}`);
      },
      initialValue: "",
      textLabel: "CAN",
      title: "CAN",
      onChange: (e) => {
        let filteredValue = e.target.value.replace(/[^0-9]/g, "");
        return filteredValue.substr(0, 4);
      },
    });
  };

  const handleTitleClick = (brand) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (title) => {
        ++updatesLockCounter.current;

        let requestId = GenerateInteger();
        lastRequestId.current = requestId;
        backend.bckCarsCreateOrUpdateBrand(brand.id, title).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
            let brandId = json.content;
            setTableData((prev) => {
              var foundIndex = prev.findIndex((x) => x.id === brandId);
              if (foundIndex !== -1) {
                prev[foundIndex].name = title;
              }
              return [...prev];
            });
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
      initialValue: brand.name,
      textLabel: t("Title"),
      title: t("Title"),
    });
  };

  const handleRemoveBrand = (brand) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove brand?", { title: brand.name }),
      handleConfirm: () => {
        ++updatesLockCounter.current;
        backend.bckCarsRemoveBrand(brand.id).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
            setTableData((prev) => {
              let foundIndex = prev.findIndex((x) => x.id === brand.id);
              if (foundIndex !== -1) {
                prev.splice(foundIndex, 1);
              }
              return [...prev];
            });
          } else if (json.code === 3) {
            setError(t(json.message));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const handleUploadImage = (brand, file) => {
    if (brand.imageUrl) {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Overwrite brand image?"),
        handleConfirm: () => {
          handleUploadImageAccepted(brand, file);
        },
      });
    } else {
      handleUploadImageAccepted(brand, file);
    }
  };

  const handleUploadImageAccepted = (brand, file) => {
    if (file.size > 16 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    backend.bckFilesUploadCarBrandImage(brand.id, file).then((json) => {
      if (json.code === 0) {
        setTableData((prev) => {
          let next = [...prev];
          let target = next.find((r) => r.id === brand.id);
          if (target) target.imageUrl = json.content;
          return next;
        });
      } else {
        setError(json.message);
      }
    });
  };

  return (
    <>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <Typography variant="h5" color="textSecondary">
            {t("Brands table")}
          </Typography>
          <Typography color="secondary">{error}</Typography>
          <Grid item>
            <Button
              onClick={handleSearchEquipmentsByCanOpen}
              variant="contained"
              color="primary"
            >
              {t("Equipments by CAN")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleCreateBrandOpen}
              variant="contained"
              color="primary"
            >
              {t("Add brand")}
            </Button>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={searchParameters.onlyWithModels}
                  onChange={() => {
                    let newValue = !searchParameters.onlyWithModels;
                    setSearchParameters((prev) => ({
                      ...prev,
                      onlyWithModels: newValue,
                    }));
                    settingRepositorySave(
                      SettingName.CAR_BRANDS_ONLY_WITH_MODELS,
                      newValue
                    );
                  }}
                  color="primary"
                />
              }
              label={t("Only with models")}
            />
          </Grid>
          <div style={{ maxHeight: "70vh", overflow: "auto" }}>
            <CarBrandsTable
              data={tableData}
              handleShowCarBrandImage={handleShowCarBrandImage}
              handleShowBrandModels={handleShowBrandModels}
              handleRemoveBrand={handleRemoveBrand}
              handleUploadImage={handleUploadImage}
              handleTitleClick={handleTitleClick}
            />
          </div>
        </Grid>
      </Paper>
    </>
  );
}
