import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Tooltip,
  styled,
  TableContainer,
} from "@mui/material";

import {
  Check as YesIcon,
  Error as AdviceIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { DigitalBusesGroups } from "../../../enums/DigitalBusesGroups";
import { getCarStatusGroupString } from "../../../enums/CarStatusGroups";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.light,
  },
  td: {
    textAlign: "center !important",
  },
}));

export default function EquipmentStatusesTable({
  data,
  digitalBusesGroups,
  isEditable,
  onShowPromptClick,
  onCellClick,
  onEditPromptClick,
}) {
  const { t } = useTranslation();

  const boolToTrueIcon = (value) => {
    let icon = value ? (
      <YesIcon className={styles.scalableIcon} style={{ color: "green" }} />
    ) : (
      <></>
    );
    return <div style={{ textAlign: "center" }}>{icon}</div>;
  };

  const columnWidth = 100 / (digitalBusesGroups?.length * 2) - 1;

  const groupedStatuses =
    data?.reduce((p, c, i, a) => {
      let strId = "" + c.groupId;
      if (p[strId]) {
        p[strId].push(c);
      } else {
        p[strId] = [c];
      }
      return p;
    }, {}) ?? {};

  return (
    <TableContainer style={{ maxHeight: "85vh" }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2}>{t("Parameter")}</TableCell>
            <TableCell
              colSpan={digitalBusesGroups?.length}
              style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
            >
              {t("Executable commands")}
            </TableCell>
            <TableCell
              colSpan={digitalBusesGroups?.length}
              style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
            >
              {t("Readable statuses")}
            </TableCell>
          </TableRow>
          <TableRow>
            {digitalBusesGroups?.map((dbp, i) => (
              <TableCell
                key={`CMD-${dbp}`}
                id={`CMD-${dbp}`}
                width={`${columnWidth}%`}
                style={{
                  textAlign: "center",
                  top: 37,
                  borderLeft: i === 0 ? "1px solid rgba(224, 224, 224, 1)" : "",
                }}
              >
                {DigitalBusesGroups[dbp]}
              </TableCell>
            ))}
            {digitalBusesGroups?.map((dbp, i) => (
              <TableCell
                key={`STS-${dbp}`}
                id={`STS-${dbp}`}
                width={`${columnWidth}%`}
                style={{
                  textAlign: "center",
                  top: 37,
                  borderLeft: i === 0 ? "1px solid rgba(224, 224, 224, 1)" : "",
                }}
              >
                {DigitalBusesGroups[dbp]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(groupedStatuses).map((groupId) => (
            <>
              <StyledTableRow key={"header" + groupId}>
                <TableCell colSpan={(digitalBusesGroups?.length ?? 0) * 2 + 1}>
                  <b>{getCarStatusGroupString(t, parseInt(groupId))}</b>
                </TableCell>
              </StyledTableRow>
              {groupedStatuses[groupId]?.map((s) => (
                <StyledTableRow key={s.id}>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        textAlign: "left",
                      }}
                    >
                      {s.name}
                      {s.prompt ? (
                        <IconButton
                          color="primary"
                          onClick={() => onShowPromptClick(s)}
                        >
                          <AdviceIcon />
                        </IconButton>
                      ) : (
                        <></>
                      )}
                      {isEditable ? (
                        <IconButton
                          color="primary"
                          onClick={() => onEditPromptClick(s)}
                        >
                          <EditIcon />
                        </IconButton>
                      ) : (
                        <></>
                      )}
                    </div>
                  </TableCell>
                  {digitalBusesGroups?.map((dbp, i) => (
                    <Tooltip
                      key={`CMD-${dbp}`}
                      id={`CMD-${dbp}`}
                      title={DigitalBusesGroups[dbp]}
                    >
                      <TableCell
                        className={styles.hoverableCell}
                        style={
                          i === 0
                            ? { borderLeft: "1px solid rgba(224, 224, 224, 1)" }
                            : {}
                        }
                        onClick={() => onCellClick(s, 0, dbp)}
                      >
                        {boolToTrueIcon(s.commandCan?.find((cc) => cc === dbp))}
                      </TableCell>
                    </Tooltip>
                  ))}
                  {digitalBusesGroups?.map((dbp, i) => (
                    <Tooltip
                      key={`STS-${dbp}`}
                      id={`STS-${dbp}`}
                      title={DigitalBusesGroups[dbp]}
                    >
                      <TableCell
                        className={styles.hoverableCell}
                        style={
                          i === 0
                            ? { borderLeft: "1px solid rgba(224, 224, 224, 1)" }
                            : {}
                        }
                        onClick={() => onCellClick(s, 1, dbp)}
                      >
                        {boolToTrueIcon(s.statusCan?.find((cc) => cc === dbp))}
                      </TableCell>
                    </Tooltip>
                  ))}
                </StyledTableRow>
              ))}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
