import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";

import Widget from "../components/Widget/Widget";
import {
  Refresh as RefreshIcon,
  AddCircle as AddCircleIcon,
} from "@mui/icons-material";

import { useInterval } from "../useInterval";
import { GenerateInteger } from "../helpers/RandomGenerator";

import ConfigurationsTable from "../components/Tables/ConfigurationsTable";
import ConfigurationDialog from "../components/Dialogs/ConfigurationDialog";
import { UserPermissions } from "../enums/UserRoles";
import { LanguageConverter } from "../enums/LanguageConverter";
import { useTranslation } from "react-i18next";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import AdvancedTablePagination from "../components/AdvancedTablePagination";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoadBool,
  settingRepositoryLoadInt,
  settingRepositorySave,
} from "../settingRepository";

export default function Configurations() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const { t, i18n } = useTranslation();
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [table, setTable] = useState([]);
  const [isConfigurationOpened, setConfigurationOpened] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [totalConfigurations, setTotalConfigurations] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
  const [carBrands, setCarBrands] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [alarmModels, setAlarmModels] = useState([]);
  const [searchCarBrandInput, setSearchCarBrandInput] = useState(-1);
  const [searchCarModelInput, setSearchCarModelInput] = useState(-1);
  const [searchAlarmModelInput, setSearchAlarmModelInput] = useState(-1);
  const [searchLanguageInput, setSearchLanguageInput] = useState(-1);
  const [searchCarBrand, setSearchCarBrand] = useState("");
  const [searchCarModel, setSearchCarModel] = useState("");
  const [searchAlarmModel, setSearchAlarmModel] = useState("");
  const [searchLanguage, setSearchLanguage] = useState("");
  const [searchUserInput, setSearchUserInput] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [autoUpdateChecked, setAutoUpdateChecked] = useState(true);
  const [filterByCompleteness, setFilterByCompleteness] = useState("");
  const [filterByCompletenessInput, setFilterByCompletenessInput] =
    useState(-1);
  const [onlyMineChecked, setOnlyMineChecked] = useState(false);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  var permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
  var editAllowed =
    permissions.includes(UserPermissions.CONFIGURATIONS_EDIT_ALL) ||
    permissions.includes(UserPermissions.CONFIGURATIONS_EDIT_OWN);

  const resetSearchParams = () => {
    setSearchCarBrand("");
    setSearchCarBrandInput(-1);
    setSearchCarModel("");
    setSearchCarModelInput(-1);
    setSearchAlarmModel("");
    setSearchAlarmModelInput(-1);
    setSearchLanguage("");
    setSearchLanguageInput(-1);
    setSearchUser("");
    setSearchUserInput("");
    setCarModels([]);
    setFilterByCompleteness("");
    setFilterByCompletenessInput(-1);
    setCurrentPage(0);
  };

  const editConfigurationClick = (configuration) => {
    setSelectedConfig(configuration);
    setConfigurationOpened(true);
  };

  const deleteConfigurationClick = (configuration) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete configuration?", {
        configuration: configuration.name,
      }),
      handleConfirm: () => {
        ++updatesLockCounter.current;
        setTable((prev) => {
          var foundIndex = prev.findIndex((x) => x.id === configuration.id);
          prev.splice(foundIndex, 1);
          return prev;
        });

        backend.bckConfigurationsDelete(configuration.id).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
          } else if (json.code === 3) {
            console.log(json.message);
          } else {
            console.log(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const createConfigurationClick = () => {
    ++updatesLockCounter.current;
    backend.bckConfigurationsGetInvalidConfigsCount().then((json) => {
      --updatesLockCounter.current;
      if (json.code === 0) {
        if (json.content > 3) {
          dialogDispatch({
            type: DialogActionTypes.ConfirmationDialogOpen,
            userMessage: t("Please fix invalid configurations (full message)", {
              count: json.content,
            }),
            handleConfirm: () => {
              setConfigurationOpened(true);
            },
            closeText: t("Cancel"),
            confirmText: t("Continue"),
          });
        } else {
          setConfigurationOpened(true);
        }
      } else if (json.code === 3) {
        console.log(json.message);
      } else {
        console.log(t("REQUEST_ERROR"));
      }
    });
  };

  const configurationDialogClose = () => {
    setConfigurationOpened(false);
  };

  const refreshTable = useCallback(
    (
      currentPage,
      currentRowsPerPage,
      searchCarBrand,
      searchCarModel,
      searchAlarmModel,
      searchLanguage,
      searchUser,
      filterByCompleteness
    ) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckConfigurationsList(
          searchCarBrand,
          searchCarModel,
          searchAlarmModel,
          searchLanguage,
          searchUser,
          LanguageConverter[i18n.language],
          filterByCompleteness,
          currentPage + 1,
          currentRowsPerPage
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
            if (lastRequestId.current === id) {
              setTable((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.configurations;
                } else {
                  return prev;
                }
              });
              setTotalConfigurations((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.count;
                } else {
                  return prev;
                }
              });
            }
          } else if (json.code === ResponseCode.ACCESS_DENIED) {
            console.log(t("AUTH_ERROR"));
          } else {
            console.log(t("REQUEST_ERROR"));
          }

          if (isLoading) setIsLoading(false);
        });
    },
    [i18n.language, isLoading, t, backend]
  );

  const configurationDialogFinish = () => {
    refreshTable(
      currentPage,
      currentRowsPerPage,
      searchCarBrand,
      searchCarModel,
      searchAlarmModel,
      searchLanguage,
      searchUser,
      filterByCompleteness
    );
    setConfigurationOpened(false);
  };

  const loadCarBrands = (backend) => {
    backend.bckCarsListBrands(true, true, false, null).then((json) => {
      setCarBrands(json.content);
    });
  };

  const loadAlarms = () => {
    backend.bckDevicesList("", true, false).then((json) => {
      setAlarmModels(json.content);
    });
  };

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    if (!isInitializing) {
      refreshTable(
        currentPage,
        currentRowsPerPage,
        searchCarBrand,
        searchCarModel,
        searchAlarmModel,
        searchLanguage,
        searchUser,
        filterByCompleteness
      );
    }
  }, [i18n.language]);

  useEffect(() => {
    if (isInitializing) {
      setAutoUpdateChecked(
        settingRepositoryLoadBool(SettingName.AUTO_UPDATE_CONFIGURATIONS, true)
      );

      let pageSize = settingRepositoryLoadInt(
        SettingName.CONFIGURATIONS_PAGE_SIZE,
        10
      );
      setCurrentRowsPerPage(pageSize);

      resetSearchParams();
      loadCarBrands(backend);
      loadAlarms(backend);
      refreshTable(0, pageSize, "", "", "", "", "", "");
      setIsInitializing(false);
    }
  }, [isInitializing]);

  useInterval(() => {
    if (updatesLockCounter.current === 0 && autoUpdateChecked === true) {
      refreshTable(
        currentPage,
        currentRowsPerPage,
        searchCarBrand,
        searchCarModel,
        searchAlarmModel,
        searchLanguage,
        searchUser,
        filterByCompleteness
      );
    }
  }, 2000);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    refreshTable(
      newPage,
      currentRowsPerPage,
      searchCarBrand,
      searchCarModel,
      searchAlarmModel,
      searchLanguage,
      searchUser,
      filterByCompleteness
    );
  };

  const handleChangeRowsPerPage = (event) => {
    var newRowsPerPage = parseInt(event.target.value, 10);
    settingRepositorySave(SettingName.CONFIGURATIONS_PAGE_SIZE, newRowsPerPage);
    setCurrentRowsPerPage(newRowsPerPage);
    setCurrentPage(0);
    refreshTable(
      0,
      newRowsPerPage,
      searchCarBrand,
      searchCarModel,
      searchAlarmModel,
      searchLanguage,
      searchUser,
      filterByCompleteness
    );
  };

  const handleSearchPress = () => {
    var newSearchCarBrand = "";
    if (searchCarBrandInput === -1) {
      setSearchCarBrand("");
    } else {
      newSearchCarBrand = searchCarBrandInput;
      setSearchCarBrand(searchCarBrandInput);
    }

    var newSearchCarModel = "";
    if (searchCarModelInput === -1) {
      setSearchCarModel("");
    } else {
      newSearchCarModel = searchCarModel;
      setSearchCarModel(searchCarModelInput);
    }

    var newSearchAlarmModel = "";
    if (searchAlarmModelInput === -1) {
      setSearchAlarmModel("");
    } else {
      newSearchAlarmModel = searchAlarmModelInput;
      setSearchAlarmModel(searchAlarmModelInput);
    }

    var newSearchLanguage = "";
    if (searchLanguageInput === -1) {
      setSearchLanguage("");
    } else {
      newSearchLanguage = searchLanguageInput;
      setSearchLanguage(searchLanguageInput);
    }

    var newSearchUser = "";
    if (searchUserInput.trim() === "") {
      setSearchUser("");
    } else {
      newSearchUser = searchUserInput;
      setSearchUser(searchUserInput);
    }

    var newFilterByCompleteness = "";
    if (filterByCompletenessInput === -1) {
      setFilterByCompleteness("");
    } else {
      newFilterByCompleteness = filterByCompletenessInput;
      setFilterByCompleteness(filterByCompletenessInput);
    }

    setCurrentPage(0);
    refreshTable(
      0,
      currentRowsPerPage,
      newSearchCarBrand,
      newSearchCarModel,
      newSearchAlarmModel,
      newSearchLanguage,
      newSearchUser,
      newFilterByCompleteness
    );
  };

  const handleSearchResetPress = () => {
    resetSearchParams();
    refreshTable(0, currentRowsPerPage, "", "", "", "", "", null);
  };

  const onSelectCarBrand = (e) => {
    setSearchCarBrandInput(e.target.value);
    setSearchCarModelInput(-1);
    if (e.target.value !== -1) {
      backend
        .bckCarsListModels(e.target.value, true, true, null)
        .then((json) => {
          setCarModels(json.content);
        });
    }
  };

  const requestDuplicateConfiguration = (config) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Duplicate configuration?", {
        configuration: config.name,
      }),
      handleConfirm: () => {
        ++updatesLockCounter.current;
        backend.bckConfigurationsDuplicate(config.id).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
          } else if (json.code === 3) {
            console.log(json.message);
          } else {
            console.log(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const duplicateConfigurationClick = (config) => {
    ++updatesLockCounter.current;
    backend.bckConfigurationsGetInvalidConfigsCount().then((json) => {
      --updatesLockCounter.current;
      if (json.code === 0) {
        if (json.content > 3) {
          dialogDispatch({
            type: DialogActionTypes.ConfirmationDialogOpen,
            userMessage: t("Please fix invalid configurations (full message)", {
              count: json.content,
            }),
            handleConfirm: () => {
              requestDuplicateConfiguration(config);
            },
            closeText: t("Cancel"),
            confirmText: t("Continue"),
          });
        } else {
          requestDuplicateConfiguration(config);
        }
      } else if (json.code === 3) {
        console.log(json.message);
      } else {
        console.log(t("REQUEST_ERROR"));
      }
    });
  };

  const onlyMineCheckedChange = () => {
    setOnlyMineChecked(!onlyMineChecked);
    let newSearchUser = "";
    if (onlyMineChecked) {
      newSearchUser = "";
    } else {
      newSearchUser = localStorage.getItem("userMail");
    }
    setSearchUserInput(newSearchUser);
    setSearchUser(newSearchUser);
    setCurrentPage(0);
    refreshTable(
      0,
      currentRowsPerPage,
      searchCarBrand,
      searchCarModel,
      searchAlarmModel,
      searchLanguage,
      newSearchUser,
      filterByCompleteness
    );
  };

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            noBodyPadding
            header={
              <React.Fragment>
                <Typography variant="h5" color="textSecondary">
                  {t("Configurations table")}
                </Typography>
                <div>
                  <IconButton
                    disabled={!editAllowed}
                    style={
                      !editAllowed ? { color: "gray" } : { color: "black" }
                    }
                    onClick={createConfigurationClick}
                  >
                    <AddCircleIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setIsLoading(true);
                      setIsInitializing(true);
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                </div>
              </React.Fragment>
            }
          >
            <Grid
              container
              direction="column"
              spacing={2}
              style={{ paddingLeft: "12px" }}
            >
              <Grid item>
                <TextField
                  label={t("User")}
                  disabled={onlyMineChecked}
                  value={searchUserInput}
                  onChange={(e) => setSearchUserInput(e.target.value)}
                  style={{ marginRight: "12px" }}
                />
                <FormControl style={{ minWidth: "25ch" }}>
                  <InputLabel>{t("Car brand")}</InputLabel>
                  <Select
                    label={t("Car brand")}
                    value={searchCarBrandInput}
                    onChange={onSelectCarBrand}
                  >
                    <MenuItem key={-1} value={-1}>
                      <em>{t("Any (fem)")}</em>
                    </MenuItem>
                    {carBrands == null
                      ? ""
                      : carBrands.map((cb) => (
                          <MenuItem key={cb.id} value={cb.id}>
                            {cb.name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
                <FormControl style={{ marginLeft: "12px", minWidth: "25ch" }}>
                  <InputLabel>{t("Car model")}</InputLabel>
                  <Select
                    label={t("Car model")}
                    value={searchCarModelInput}
                    onChange={(e) => setSearchCarModelInput(e.target.value)}
                  >
                    <MenuItem key={-1} value={-1}>
                      <em>{t("Any (fem)")}</em>
                    </MenuItem>
                    {carModels.map((cb) => (
                      <MenuItem key={cb.id} value={cb.id}>
                        {cb.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl style={{ marginLeft: "12px", minWidth: "25ch" }}>
                  <InputLabel>{t("Alarm")}</InputLabel>
                  <Select
                    label={t("Alarm")}
                    value={searchAlarmModelInput}
                    onChange={(e) => setSearchAlarmModelInput(e.target.value)}
                  >
                    <MenuItem key={-1} value={-1}>
                      <em>{t("Any (fem)")}</em>
                    </MenuItem>
                    {alarmModels.map((a) => (
                      <MenuItem key={a.id} value={a.id}>
                        {a.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl style={{ marginLeft: "12px", minWidth: "12ch" }}>
                  <InputLabel>{t("Language")}</InputLabel>
                  <Select
                    label={t("Language")}
                    value={searchLanguageInput}
                    onChange={(e) => setSearchLanguageInput(e.target.value)}
                  >
                    <MenuItem key={-1} value={-1}>
                      <em>{t("Any (mas)")}</em>
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      RU
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      EN
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl style={{ marginLeft: "12px", minWidth: "20ch" }}>
                  <InputLabel>{t("Completeness")}</InputLabel>
                  <Select
                    label={t("Completeness")}
                    value={filterByCompletenessInput}
                    onChange={(e) =>
                      setFilterByCompletenessInput(e.target.value)
                    }
                  >
                    <MenuItem key={-1} value={-1}>
                      <em>{t("Any (fem)")}</em>
                    </MenuItem>
                    <MenuItem key={1} value={"true"}>
                      {t("Completed")}
                    </MenuItem>
                    <MenuItem key={2} value={"false"}>
                      {t("Not completed")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item>
                  <Button
                    onClick={handleSearchPress}
                    variant="contained"
                    color="primary"
                  >
                    {t("Search")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleSearchResetPress}
                    disabled={
                      searchCarBrandInput === -1 &&
                      searchCarModelInput === -1 &&
                      searchAlarmModelInput === -1 &&
                      searchLanguageInput === -1 &&
                      searchUser === "" &&
                      filterByCompleteness === ""
                    }
                    variant="contained"
                    color="primary"
                  >
                    {t("Reset parameters")}
                  </Button>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={autoUpdateChecked}
                        onChange={() => {
                          setAutoUpdateChecked(!autoUpdateChecked);
                          settingRepositorySave(
                            SettingName.AUTO_UPDATE_CONFIGURATIONS,
                            !autoUpdateChecked
                          );
                        }}
                        color="primary"
                      />
                    }
                    label={t("Auto update")}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={onlyMineChecked}
                        onChange={onlyMineCheckedChange}
                        color="primary"
                      />
                    }
                    label={t("Only mine")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <AdvancedTablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              totalRows={totalConfigurations}
              pageSize={currentRowsPerPage}
              pageNumber={currentPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              <ConfigurationsTable
                data={table}
                deleteAllowed={editAllowed}
                onEditClick={editConfigurationClick}
                onDeleteClick={deleteConfigurationClick}
                onDuplicateClick={duplicateConfigurationClick}
              />
            </AdvancedTablePagination>
          </Widget>
        </Grid>
      </Grid>

      <ConfigurationDialog
        isOpened={isConfigurationOpened}
        config={selectedConfig}
        handleClose={configurationDialogClose}
        handleCreate={configurationDialogFinish}
        saveAllowed={editAllowed}
      />
    </>
  );
}
