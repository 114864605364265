import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
} from "@mui/material";

import {
  ArrowForwardIos as ArrowIcon,
  ArrowBack as BackIcon,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";

export default function SelectPreferencesDialog({
  isOpened,
  defaultPreferredBrands,
  defaultPreferredModels,
  handleConfirm,
  handleClose,
}) {
  const { t } = useTranslation();
  const backend = useBackend();
  const [brandsList, setBrandsList] = useState(null);
  const [modelsList, setModelsList] = useState(null);
  const [preferredBrands, setPreferredBrands] = useState([]);
  const [preferredModels, setPreferredModels] = useState([]);
  const [selectedBrandName, setSelectedBrandName] = useState(null);
  const [isModelMode, setIsModelMode] = useState(false);
  const [isLoadingInProgress, setIsLoadingInProgress] = useState(false);

  useEffect(() => {
    if (isOpened) {
      if (brandsList === null) {
        backend.bckCarsListBrands(false, true, false, null).then((json) => {
          if (json.code === 0) {
            setBrandsList(json.content);
          }
        });
      }
      setPreferredBrands(defaultPreferredBrands);
      setPreferredModels(defaultPreferredModels);
      setIsModelMode(false);
      setIsLoadingInProgress(false);
    }
  }, [
    isOpened,
    brandsList,
    defaultPreferredBrands,
    defaultPreferredModels,
    backend,
  ]);

  const handleBrandCheck = (brandId, brandName, value) => {
    if (value) {
      if (preferredBrands.every((pb) => pb.id !== brandId)) {
        setPreferredBrands((prev) => [
          ...prev,
          { id: brandId, name: brandName },
        ]);
      }
    } else {
      if (!preferredBrands.every((pb) => pb.id !== brandId)) {
        setPreferredBrands((prev) => {
          var newa = [...prev];
          newa.splice(
            prev.findIndex((v) => v.id === brandId),
            1
          );
          return newa;
        });
      }
    }
  };

  const handleModelCheck = (modelId, modelName, value) => {
    if (value) {
      if (preferredModels.every((pb) => pb.id !== modelId)) {
        setPreferredModels((prev) => [
          ...prev,
          { id: modelId, name: modelName, brandName: selectedBrandName },
        ]);
      }
    } else {
      if (!preferredModels.every((pb) => pb.id !== modelId)) {
        setPreferredModels((prev) => {
          var newa = [...prev];
          newa.splice(
            prev.findIndex((v) => v.id === modelId),
            1
          );
          return newa;
        });
      }
    }
  };

  const switchToModelMode = (brand) => {
    if (!isLoadingInProgress) {
      setIsLoadingInProgress(true);
      backend.bckCarsListModels(brand.id, false, true, null).then((json) => {
        setIsLoadingInProgress(false);
        if (json.code === 0) {
          setIsModelMode(true);
          setSelectedBrandName(brand.name);
          setModelsList(json.content);
        }
      });
    }
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{t("Preferences")}</DialogTitle>
      <DialogContent>
        {isModelMode ? (
          <IconButton
            color="primary"
            onClick={() => {
              setIsModelMode(false);
            }}
            style={{
              position: "absolute",
              top: 10,
              right: 40,
            }}
          >
            <BackIcon />
          </IconButton>
        ) : (
          <></>
        )}
        <Table size="small">
          <TableHead></TableHead>
          <TableBody>
            {isModelMode ? (
              modelsList && preferredModels ? (
                modelsList.map((model) => (
                  <TableRow key={model.id}>
                    <TableCell>{model.name}</TableCell>
                    <TableCell style={{ width: "64px" }}>
                      <Checkbox
                        checked={
                          !preferredModels.every((pm) => pm.id !== model.id)
                        }
                        onChange={(e) => {
                          handleModelCheck(
                            model.id,
                            model.name,
                            e.target.checked
                          );
                        }}
                        color="primary"
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <></>
              )
            ) : brandsList && preferredBrands ? (
              brandsList.map((brand) => (
                <TableRow key={brand.id}>
                  <TableCell>{brand.name}</TableCell>
                  <TableCell style={{ width: "64px" }}>
                    <Checkbox
                      checked={
                        !preferredBrands.every((pb) => pb.id !== brand.id)
                      }
                      onChange={(e) => {
                        handleBrandCheck(
                          brand.id,
                          brand.name,
                          e.target.checked
                        );
                      }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell style={{ width: "64px" }}>
                    <IconButton
                      color="primary"
                      disabled={isLoadingInProgress}
                      onClick={() => {
                        switchToModelMode(brand);
                      }}
                    >
                      <ArrowIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Cancel")}
        </Button>
        <Button
          onClick={() => handleConfirm(preferredBrands, preferredModels)}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
