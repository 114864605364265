const CarStatusGroups = {
  1: "Main",
  2: "Extended",
  3: "EV",
  4: "Trailer",
};
Object.freeze(CarStatusGroups);

function getCarStatusGroupString(t, id) {
  switch (id) {
    case 1:
      return t("Main parameters");
    case 2:
      return t("Extended parameters");
    case 3:
      return t("EV parameters");
    case 4:
      return t("Trailer parameters");
    default:
      return "Unknown car status group";
  }
}

export { CarStatusGroups, getCarStatusGroupString };
